import acai from "./resources/acai.webp";
import toast from "./resources/toast.webp";
import smoothie from "./resources/smoothie.webp";
import downtown from "./resources/downtown.webp";
import ballard from "./resources/ballard.webp";
import capitol from "./resources/capitol.webp";
import logo from "./resources/logo.svg";
import hero from "./resources/hero.webp";

export const logoImage: string = logo;

export const orderNow: string = "https://store.vervebowls.com/";

export const locationOrderLinks: Record<string, string> = {
  BALLARD:
    "https://www.smorefood.com/4q289s97/verve-bowls--ballard-seattle-98107/order-online",
  "CAPITOL HILL":
    "https://www.smorefood.com/zla4b28b/verve-bowls--capitol-hill-seattle-98122/order-online",
  DOWNTOWN:
    "https://www.smorefood.com/x2fgvpkq/verve-bowls--downtown-seattle-98101/order-online",
};

export const navPills: Record<string, string> = {
  MENU: "https://store.vervebowls.com/",
  DELIVERY:
    "https://order.online/online-ordering/business/verve-bowls-101789?delivery=true",
  CATERING: "http://ezcater.com/catering/verve-bowls-3",
  "GIFT CARDS": "https://squareup.com/gift/4D5VXACD884NM/order",
  SOCIAL: "https://store.vervebowls.com/s/instagram",
  CAREERS: "https://www.indeed.com/cmp/Verve-Bowls-1",
};

export const announcement: JSX.Element = (
  <>
    We are thrilled to announce that Verve Bowls will now be extending our hours
    until 7pm every day!
  </>
);

export const heroImage: string = hero;

export const heroContentHeader: string = "The Verve Culture";

export const heroContentBody: JSX.Element = (
  <>
    Community is at the heart of everything we do. We believe in creating fresh
    & nutritious handcrafted bowls to support the foundation for a balanced
    lifestyle. We promise smiles, an infectious energy, and to always make
    things right.
    <br />
    We believe in education, conversation and the power of a healthy lifestyle
    being accessible to anyone & everyone. We thrive on raw, healthful
    ingredients. We love our people, our community and giving back. Get to know
    us. Come by & learn through each bite.
  </>
);

export type MenuItem = {
  label: string;
  image: string;
  link: string;
};

export const menu: MenuItem[] = [
  {
    label: "ACAI",
    image: acai,
    link: "https://store.vervebowls.com/?location=11ea68c81f72dd63bd870cc47a2b63e4#19/",
  },
  {
    label: "TOAST",
    image: toast,
    link: "https://store.vervebowls.com/?location=11ea68c81f72dd63bd870cc47a2b63e4#21",
  },
  {
    label: "SMOOTHIE",
    image: smoothie,
    link: "https://store.vervebowls.com/?location=11ea68c81f72dd63bd870cc47a2b63e4#24",
  },
];

export type Location = {
  label: string;
  image: string;
  link: string;
  address: string;
  phone: string;
  hours: string;
  email: string;
  orderLink: string; // Added secondary order link for each location
};

export const locations: Location[] = [
  {
    label: "Downtown",
    image: downtown,
    link: "https://maps.app.goo.gl/3ojr9ryYdiZYrfYY7",
    phone: "206-941-5184",
    address: "106 Pine St, Seattle, WA 98101",
    hours: "7am - 7pm", // Updated per announcement
    email: "downtown@vervebowls.com",
    orderLink:
      "https://www.smorefood.com/x2fgvpkq/verve-bowls--downtown-seattle-98101/order-online",
  },
  {
    label: "Ballard",
    image: ballard,
    link: "https://maps.app.goo.gl/swrrgQcAuXigixEf7",
    phone: "206-402-5766",
    address: "1764 NW 56th St, Seattle, WA 98107",
    hours: "7am - 7pm", // Updated per announcement
    email: "ballard@vervebowls.com",
    orderLink:
      "https://www.smorefood.com/4q289s97/verve-bowls--ballard-seattle-98107/order-online",
  },
  {
    label: "Capitol Hill",
    image: capitol,
    link: "https://maps.app.goo.gl/rqRgEtidGadPtpV97",
    phone: "206-422-1319",
    address: "715 E Pine St, Seattle, WA 98122",
    hours: "7am - 7pm", // Updated per announcement
    email: "capitolhill@vervebowls.com",
    orderLink:
      "https://www.smorefood.com/zla4b28b/verve-bowls--capitol-hill-seattle-98122/order-online",
  },
];

export const contactFormRecipient: string = "info@vervebowls.com";

export const socialBoard: string =
  "https://www.pinterest.com/vervebowls/verve-bowls/";

export const socialIcons: Array<string> = [
  "https://www.yelp.com/biz/verve-bowls-capitol-hill-seattle",
  "https://www.tripadvisor.com/Restaurant_Review-g60878-d10796970-Reviews-Verve_Bowls-Seattle_Washington.html",
  "https://www.instagram.com/vervebowls/",
  "https://www.pinterest.com/vervebowls/verve-bowls",
  "https://www.facebook.com/vervebowls",
  "https://twitter.com/vervebowls",
];

export const footerHeader: string = "Live Vervaciously!";
export const footerCopyright: string = "2024 Vaghasia VB LLC";

export type Manager = {
  name: string;
  phone: string;
  title: string;
  email: string;
};

export const management: Manager[] = [
  {
    name: "Jainul Vaghasia",
    email: "vaghasia.vb@vervebowls.com",
    phone: "206-605-0107",
    title: "Co-owner",
  },
  {
    name: "Krushang Vaghasia",
    email: "vaghasia.vb@vervebowls.com",
    phone: "716-587-2073",
    title: "Co-owner",
  },
  {
    name: "Paul Biangco",
    email: "paul.biangco@vervebowls.com",
    phone: "757-679-0382",
    title: "Regional Manager",
  },
];
