import React from "react";
import "./App.css";
import Divider from "./resources/bot-divider.svg";
import { useResponsiveness } from "react-responsiveness";
import Hamburger_icon from "./resources/Hamburger_icon.svg";
import { slide as HamburgerMenu } from "react-burger-menu";
import { SocialIcon } from "react-social-icons";
import {
  contactFormRecipient,
  footerCopyright,
  footerHeader,
  heroContentBody,
  heroContentHeader,
  heroImage,
  locations,
  locationOrderLinks,
  logoImage,
  menu,
  navPills,
  orderNow,
  socialBoard,
  socialIcons,
} from "./data";
import { Route, Routes } from "react-router";
import { TeamPortal } from "./TeamPortal";

function App() {
  const { isMax } = useResponsiveness();

  return (
    <div className="App">
      {isMax("sm") ? Header() : MobileHeader()}
      <object
        data={Divider}
        type="image/svg+xml"
        style={{ width: "100%", height: "24px" }}
        role="presentation"
      ></object>
      <RoutesTree />
      {Footer()}
    </div>
  );
}

const Home = () => {
  return (
    <>
      {HeroBanner()}
      {Menu()}
      {Social()}
      {Locations()}
      <object
        data={Divider}
        type="image/svg+xml"
        style={{ width: "100%", height: "24px" }}
        role="presentation"
      ></object>
      {ContactForm()}
    </>
  );
};

const MobileHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);

  const onMenuOpen = () => {
    setIsMenuOpen(true);
    document.querySelector("body")?.classList.add("disable-scroll");
  };

  const onMenuClose = () => {
    document.querySelector("body")!.classList.remove("disable-scroll");
    setIsMenuOpen(false);
  };

  return (
    <div className="Main-nav">
      <div className="Nav-hamburger">
        <HamburgerMenu
          customBurgerIcon={<img src={Hamburger_icon} alt="verve-menu" />}
          onOpen={onMenuOpen}
          onClose={onMenuClose}
          isOpen={isMenuOpen}
        >
          {/* Regular nav pills first */}
          {Object.entries(navPills).map((pill) => {
            return (
              <div className="Nav-pill">
                <h2>
                  <a
                    target="_self"
                    href={pill[1]}
                    className="App-link"
                    onClick={onMenuClose}
                  >
                    {pill[0]}
                  </a>
                </h2>
              </div>
            );
          })}

          <div className="Nav-pill">
            <h2>
              <a
                onClick={onMenuClose}
                target="_self"
                href="#contact-anchor"
                className="App-link"
              >
                CONTACT
              </a>
            </h2>
          </div>

          {/* Location-specific ordering */}
          <div className="Location-order-header">ORDER BY LOCATION</div>

          {Object.entries(locationOrderLinks).map(([location, link]) => (
            <div className="Nav-pill Location-option">
              <h3>
                <a
                  target="_self"
                  href={link}
                  className="App-link Location-order-link"
                  onClick={onMenuClose}
                >
                  {location}
                </a>
              </h3>
            </div>
          ))}
        </HamburgerMenu>
      </div>
      <header className="App-header">
        <a href="/">
          <img src={logoImage} className="App-logo" alt="verve-logo" />
        </a>
      </header>
      <div className="Nav-order-now-container">
        <a className="Nav-order-now" href={orderNow} target="_top">
          <div className="Nav-order-now-pill">ORDER NOW</div>
        </a>
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div className="Main-nav">
      <header className="App-header">
        <a href="/">
          <img src={logoImage} className="App-logo" alt="verve-logo" />
        </a>
      </header>

      {/* Regular nav pills */}
      {Object.entries(navPills).map((pill) => {
        return (
          <div className="Nav-pill">
            <h2>
              <a target="_self" href={pill[1]} className="App-link">
                {pill[0]}
              </a>
            </h2>
          </div>
        );
      })}

      {/* Location orders dropdown */}
      <div className="Nav-pill Desktop-dropdown">
        <h2>
          <a target="_self" href="#" className="App-link Dropdown-trigger">
            ORDER BY LOCATION
          </a>
        </h2>
        <div className="Desktop-dropdown-content">
          {Object.entries(locationOrderLinks).map(([location, link]) => (
            <a href={link} target="_blank" rel="noreferrer">
              {location}
            </a>
          ))}
        </div>
      </div>

      <div className="Nav-pill">
        <h2>
          <a target="_self" href="#contact-anchor" className="App-link">
            CONTACT
          </a>
        </h2>
      </div>

      {/* Primary order now button */}
      <a className="Nav-order-now" href={orderNow} target="_top">
        <div className="Nav-order-now-pill">ORDER NOW</div>
      </a>
    </div>
  );
};

const HeroBanner = () => {
  return (
    <div className="Hero">
      <div className="Hero-image">
        <img src={heroImage} alt="verve-hero" />
      </div>
      <div className="Hero-content">
        <h1 className="Hero-content-header">{heroContentHeader}</h1>
        <div className="Hero-content-body">{heroContentBody}</div>
      </div>
    </div>
  );
};

const Menu = () => {
  return (
    <div className="Menu">
      {menu.map((item, i) => {
        const { label, image, link } = item;
        return (
          <>
            {i !== 0 && <div style={{ marginRight: 10 }} />}
            <div className="Menu-item">
              <a target="_self" href={link}>
                <img src={image} alt={`verve-${label}`} />
              </a>
              <div className="Menu-item-diagonal-box">
                <h2 className="Menu-item-diagonal-box-text">
                  <a
                    target="_self"
                    href={link}
                    className="Menu-item-diagonal-box-text"
                  >
                    {`${label} >`}
                  </a>
                </h2>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

const Locations = () => {
  return (
    <div className="Locations">
      {locations.map((location, i) => (
        <div className="Locations-item">
          {i !== 0 && <div style={{ marginRight: 10 }} />}
          <img src={location.image} alt={`verve-${location.label}`} />
          <h2 className="Locations-details">{location.label}</h2>
          <div className="Locations-subdetails">
            <a
              target="_blank"
              href={location.link}
              rel="noreferrer"
              className="Locations-subdetails-link"
            >
              {location.address}
            </a>
            <br />
            <a
              target="_blank"
              href={`tel:${location.phone}`}
              rel="noreferrer"
              className="Locations-subdetails-link"
            >
              {location.phone}
            </a>
            <div>{location.hours}</div>
            <div className="Location-specific-order">
              <a
                href={location.orderLink}
                target="_blank"
                rel="noreferrer"
                className="Location-order-button"
              >
                ORDER FROM THIS LOCATION
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  return (
    // https://formsubmit.co/
    <div className="Contact-form">
      <a
        id="contact-anchor"
        style={{ paddingTop: "18vh", marginTop: "-18vh" }}
      />
      <div className="Contact-form-header">Contact Us</div>
      <form
        action={`https://formsubmit.co/${contactFormRecipient}`}
        method="POST"
        onSubmit={() => {
          setIsSubmitting(true);
        }}
      >
        <label>
          <div className="Contact-form-label"> Name: </div>
          <input
            className="Contact-form-input"
            type="text"
            name="name"
            required
          />
        </label>
        <label>
          <div className="Contact-form-label"> Email: </div>
          <input
            className="Contact-form-input"
            type="email"
            name="email"
            required
          />
        </label>
        <label>
          <div className="Contact-form-label"> Subject: </div>
          <input className="Contact-form-input" type="text" name="_subject" />
        </label>
        <label>
          <div className="Contact-form-label"> Message: </div>
          <textarea
            className="Contact-form-text"
            name="message"
            rows={10}
            required
          ></textarea>
        </label>
        <input
          type="hidden"
          name="_blacklist"
          value="spammy pattern, banned term, phrase"
        />
        <input
          type="hidden"
          name="_next"
          value="https://www.vervebowls.com/#contact-anchor"
        />
        <input type="hidden" name="_captcha" value="false" />
        <label>
          <button
            className="Contact-form-submit"
            type="submit"
            disabled={isSubmitting}
          >
            SEND
          </button>
        </label>
      </form>
    </div>
  );
};

const Social = () => {
  return (
    <div className="Social-board">
      <a
        data-pin-do="embedBoard"
        data-pin-board-width="1500"
        data-pin-scale-height="600"
        data-pin-scale-width="80"
        href={socialBoard}
      />
    </div>
  );
};

const Footer = () => {
  return (
    <div className="Footer">
      <div className="Footer-header">{footerHeader}</div>
      <div className="Footer-social">
        {socialIcons.map((icon) => (
          <SocialIcon url={icon} bgColor="gray" className="Social-icon" />
        ))}
      </div>
      <div className="Footer-copyright">&copy; {footerCopyright} </div>
      <div className="Footer-site-disclosure">
        We improve our products and advertising by using Microsoft Clarity to
        see how you use our website. By using our site, you agree that we and
        Microsoft can collect and use this data. Our{" "}
        <a
          target="_self"
          href="/privacy-statement"
          className="Footer-site-disclosure"
        >
          privacy statement
        </a>{" "}
        has more details.
      </div>
    </div>
  );
};

const PrivacyDisclosure = () => {
  return (
    <div className="Privacy-disclosure">
      <h2 className="Privacy-disclosure-header">Privacy Policy</h2>
      <div className="Privacy-disclosure-body">
        We partner with Microsoft Clarity to capture how you use and interact
        with our website through behavioral metrics, heatmaps, and session
        replay to improve and market our products/services. Website usage data
        is captured using first and third-party cookies and other tracking
        technologies to determine the popularity of products/services and online
        activity. Additionally, we use this information for site optimization,
        fraud/security purposes, and advertising. For more information about how
        Microsoft collects and uses your data, visit the{" "}
        <a target="_self" href="https://privacy.microsoft.com/privacystatement">
          Microsoft Privacy Statement
        </a>
        .
      </div>
    </div>
  );
};

type ExternalRedirectProps = {
  path: string;
};

const ExternalRedirect = (props: ExternalRedirectProps) => {
  window.location.href = props.path;
  return null;
};

const RoutesTree = () => {
  return (
    <>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/privacy-statement" element={<PrivacyDisclosure />} />
        <Route path="/team" element={<TeamPortal />} />
        <Route
          path="/menu"
          element={<ExternalRedirect path={navPills["MENU"]} />}
        />
        <Route
          path="/catering"
          element={<ExternalRedirect path={navPills["CATERING"]} />}
        />
        <Route
          path="/contact"
          element={<ExternalRedirect path="/#contact-anchor" />}
        />
        <Route path="*" element={<Home />} />
      </Routes>
    </>
  );
};

export default App;
